@font-face {
  font-family: "Bayon";
  src: url("../assets/fonts/Bebas_Neue/BebasNeue-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

.container {
  padding: 20px;
  background-color: rgb(36, 35, 112);
  min-height: 100vh;
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("../assets/images/leader_board.jpg"); /* Add this line */
  background-size: cover; /* Optional: To ensure the image covers the whole container */
  // background-position: center; /* Optional: To center the background image */
  background-repeat: no-repeat; /* Optional: To prevent the image from repeating */
  opacity: 20px;
  position: relative;
  .board_container {
    position: absolute;
    top: 0;
    width: 60%;
    height: 800px;
    border-radius: 16px;
    position: relative;
    .board_heading {
      position: absolute;
      top: 0;
      width: 100%;
      height: 170px;
      z-index: 1;
      border-radius: 16px;
      backdrop-filter: blur(2px);
      border-radius: 16px;
      background: rgba(19, 18, 18, 0.6);
      backdrop-filter: blur(2px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 20px;
      padding-bottom: 20px;
      .col1 {
        width: 50%;
        display: flex;
        justify-content: start;
        align-items: center;
        .image {
          // margin-left: 20px;
          width: 197px;
          height: 100.473px;
          flex-shrink: 0;
        }
      }
      .col2 {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: end;
        align-items: center;

        .title_leader_board {
          padding-left: 30px;
          padding-right: 30px;
          padding-top: 10px;
          padding-bottom: 10px;

          border-radius: 34px;
          background: var(
            --Linear,
            linear-gradient(
              268deg,
              #d05132 -0.29%,
              #fdcf8b 125.44%,
              #f9f0cd 216.49%
            )
          );
          color: #000;
          font-family: Roboto;
          font-size: 30px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }

    .list_container {
      margin-top: 150px;
      padding-top: 20px;
      padding-bottom: 20px;
      position: absolute;
      top: 0;
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      border-radius: 16px;
      border: 1px solid var(--Linear, #b4b4b471);
      background: linear-gradient(
        111deg,
        rgba(239, 238, 238, 0.18) 2.82%,
        rgba(239, 238, 238, 0.03) 90.94%
      );
      backdrop-filter: blur(50px);
      .list_item {
        display: flex;
        flex-direction: row;
        width: 97%;
        height: 60px;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 10px;
        border-radius: 16px;
        background: linear-gradient(
          90deg,
          rgba(172, 147, 124, 0.32) 1.45%,
          rgba(255, 185, 49, 0.32) 98.41%
        );
        backdrop-filter: blur(8px);

        .col1 {
          width: 10%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .image {
            width: 48px;
            height: 48px;
          }
          .text {
            color: #fff;
            text-align: center;
            font-family: Bayon;
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
          }
        }
        .col5 {
          width: 10%;
          height: 100%;
          display: flex;
          justify-content: start;
          align-items: center;
          .image {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            overflow: hidden;
          }
          .flag {
            font-size: 40px;
            display: inline-block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #ddd;
            text-align: center;
            line-height: 50px;
          }
        }
        .col2 {
          width: 25%;
          height: 100%;
          display: flex;
          justify-content: left;
          align-items: center;
          // background-color: #00ffea;
          .text {
            color: #fff;
            font-family: Bayon;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 83.333% */
            text-transform: uppercase;
          }
        }
        .col3 {
          width: 30%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .image {
            width: 60px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
          }
          .flag {
            font-size: 40px;
            display: inline-block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #ddd;
            text-align: center;
            line-height: 50px;
          }
        }
        .col4 {
          width: 20%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .text {
            color: #00ffea;
            font-family: Bayon;
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  
  .bottom_options_container{
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #00ffea;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 50px;
    .option1{
      width: 100px;
      background-color: #00ffea;
    }
    .option2{
      width: 100px;    
      background-color: #d05132;
    }
    .option3{
      width: 100px;
   
      background-color: #fdcf8b;
    }
  }
 
}
