.summury_container{
    margin-top: 2%;
    font-weight: bold;
    display: flex;
    align-items: center;
}
.summury_cards{
    margin-top: 5%;
  
    width: 240px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.dashboard_heading{
    font-size: 2rem;
    letter-spacing: 0.2rem;
}

.graph_switch_container{
    display: flex;
    justify-content: flex-start;
    margin-right: 2.5%;
    margin-top: 2%;
}

.graph_container{
    padding: 2%;
}

.ant-dropdown-menu{
    overflow-y: scroll;
    max-height: 250px;
}

.ant-card-body {
    padding: 0%;
}