.container {
  position: relative;

  .header {
    background-color: transparent;
    // width: 90%;
    /* Adding blur effect */
    backdrop-filter: blur(
      10px
    ); // adjust the pixel value to control the blur intensity
  }
  .dashboard_container {
    height: 100%;
    padding: 1rem;
    overflow-y: auto;
    position: relative;
    .header {
      position: fixed;
      z-index: 9999;
      width: 80%;
      height: 80px;
      background-color: transparent;
      border-radius: 15px;
      backdrop-filter: blur(5px);
      padding-left: 10px;
      /* Text styling */
      font-weight: bold; // Makes text bold
      color: black; // Makes text color black
      font-size: 30px;
      /* Centering content */
      display: flex; // Convert container to flexbox
      align-items: center; // Vertically center the content
      justify-content: start; // Horizontally center the content (optional if you want it)
      border: 2px solid rgba(0, 0, 0, 0.1);  // Subtle light gray borde
    }

    .content {
      // padding-left: 1rem;
      padding-right: 1rem;
      // margin-top: 50px;
    }
  }
}
