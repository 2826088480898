.sdks_heading{
    font-size: 2rem;
    letter-spacing: 0.2rem;
}
.sdks-title {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
  }
  
  @media (max-width: 768px) {
    .sdks-title {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 576px) {
    .sdks-title {
      font-size: 1.5rem;
    }
  }

  .page_desc{
    letter-spacing: normal;
    margin-left: 10%;
    font-size: 1.3rem;
  }