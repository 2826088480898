.container{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.container .icon_container{
    display: flex;
    justify-content: center;
}
.container .icon_heading{
    display: flex;
    flex-direction: row;
    margin-top: 2%;
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: .5rem;
}

.icon_sub_heading{
    display: flex;
    justify-items: center;
    text-align: center;
}
.connect_btn_container{
    display: flex;
    justify-items: center;
    text-align: center;
    margin-top: 2%;
}