.supported_plateform_heading{
    font-size: 2rem;
    letter-spacing: 0.2rem;
}
.supported-plateforms-title {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
  }
  
  @media (max-width: 768px) {
    .supported-plateforms-title {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 576px) {
    .supported-plateforms-title {
      font-size: 1.5rem;
    }
  }

.details-back-heading{
  font-size: 1rem;
  cursor: pointer;
}
.details-heading{
  margin-top: 1.5%;
  margin-left: 1.5%;
  font-size: 2rem;
}

.details-desc{
  font-size: 1.2rem;
  letter-spacing: normal;
  margin-left: 2.5%;
  margin-top: 1.5%;
}
.details-supported-platforms{
  font-size: 1.2rem;
  letter-spacing: normal;
  margin-left: 2.5%;
  margin-top: 1.5%;
}
.details-supported-os{
  font-size: 1.2rem;
  letter-spacing: normal;
  margin-left: 2.5%;
  margin-top: 1.5%;
}