.second_screen_heading{
    font-size: 2rem;
    letter-spacing: 0.2rem;
}
.second-screen-title {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
  }
  
  @media (max-width: 768px) {
    .second-screen-title {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 576px) {
    .second-screen-title {
      font-size: 1.5rem;
    }
  }