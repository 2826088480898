.edge_ai_heading{
    font-size: 2rem;
    letter-spacing: 0.2rem;
}
.edge-ai-title {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
  }
  
  @media (max-width: 768px) {
    .edge-ai-title {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 576px) {
    .edge-ai-title {
      font-size: 1.5rem;
    }
  }
  .ant-dropdown-menu{
    overflow-y: scroll;
    max-height: 250px;
}