.logo-editor-container{
    height: 100%;
}

.logo-editor-sdks-dropdown{
    display: flex;
    justify-content: end;
    align-items: end;
    text-align: end;
    position: relative;
}

.logo-editor-title {
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
  }
  
  @media (max-width: 768px) {
    .logo-editor-title {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 576px) {
    .logo-editor-title {
      font-size: 1.5rem;
    }
  }

.image-container{
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    height: 10rem;
     
}

.controlls-container{
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin-top: 5%;
}