/* Navbar */

.navbar {
    padding-bottom: 1px;
    border-bottom: solid 1px #001529;
    background-color: #001529;
    box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.12),
      0 6px 16px -11px rgba(0, 0, 0, 0.08);
    position: sticky;
    top: 0;
    /* margin-bottom: 20px; */
    z-index: 99990;
  }
  
  .nav-header {
    border-bottom: 0;
    padding: 0 4px 0 24px;
  }
  
  .logo {
    width: 200px;
    float: left;
  }
  .logo_txt{
    display:inline-flex;
    flex-direction: row;
    margin-left: 0%;
    font-size: 1.6rem;
    font-weight: bold;
  }
  .logo a {
    display: inline-block;
    font-size: 20px;
    text-transform: capitalize;
  }
  
  .navbar-menu {
    width: calc(100% - 200px);
    float: right;
  }
  .navbar-menu .ant-layout-header {
    padding: 0 20px;
  }
  
  .navbar-menu .ant-menu-horizontal {
    border-bottom: 0;
  }
  
  .navbar-menu .ant-menu-item {
    padding-bottom: 0px;
  }
  
  .navbar-menu .leftMenu {
    float: left;
    width: 60%;
  }
  
  .navbar-menu .rightMenu {
    float: right;
    width: 40%;
  }
  
  .menuButton {
    float: right;
    height: 32px;
    padding: 6px;
    margin-top: 14px;
    display: none;
    background: none;
    margin-right: 10px;
  }
  
  .ant-drawer-body {
    padding: 0;
  }
  
  .ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
  .ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
    display: inline-block;
    width: 100%;
  }
  
  .ant-drawer-body .ant-menu-horizontal {
    border-bottom: none;
  }
  
  .ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
    border-bottom-color: transparent;
  }
  
  .username {
    display: none;
    margin-left: 8px;
  }
  
  @media (max-width: 767px) {
    .menuButton {
      display: block;
      margin-right: 10px;
    }
  
    .leftMenu,
    .rightMenu {
      display: none;
    }
  
    .logo a {
      margin-left: -10px;
    }
  
    .ant-drawer-title > .brand-font {
      margin-bottom: 0;
    }
  
    .username {
      display: inline-block;
    }
  }
  
  .menu-item-text:hover {
    color: #1677FF; /* Change the hover text color here */
  }
  .custom-menu-item {
    background-color: #001529; /* Change the background color here */
    color: white; /* Change the text color here */
  }
  
  .custom-menu-item:hover {
    background-color: #001529;/* Change the background color on hover here */
    color: white; /* Change the text color on hover here */
  }
  /* Navbar End */
  