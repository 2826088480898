.container{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.api-keys-container{
    height: 100%;
    padding: 5rem;
}

.ticker-editor-container{
    height: 100%;
    padding: 5rem;
}

.channel-wallet-container{
    height: 100%;
    padding: 5rem;
}

.dashboard-container{
    height: 100%;
    padding: 3rem;
}

.second-screen-container{
    height: 100%;
    padding: 3rem;
}
.supported-plateforms-container{
    height: 100%;
    padding: 3rem;
}
.sdks-container{
    height: 100%;
    padding: 3rem;
}

.edge_ai-container{
    height: 100%;
    padding: 3rem;
}

.users-container{
    height: 100%;
    padding: 3rem;
}
.tabs-container{
    margin-top: 2%;
    height: 100%;
}