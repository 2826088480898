.api-key-title {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
  }
  
  @media (max-width: 768px) {
    .api-key-title {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 576px) {
    .api-key-title {
      font-size: 1.5rem;
    }
  }
.api-key-register-btn{
    display: flex;
    justify-content: end;
    align-items: center;
}