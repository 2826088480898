.channel_wallet_heading{
    font-size: 2rem;
    letter-spacing: 0.2rem;
}
.channel-wallet-title {
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
  }

  .sdks-dropdown{
    display: flex;
    justify-content: end;
    align-items: end;
    text-align: end;
    position: relative;
}
  
  @media (max-width: 768px) {
    .channel-wallet-title {
      font-size: 2rem;
    }
  }
  
  @media (max-width: 576px) {
    .channel-wallet-title {
      font-size: 1.5rem;
    }
  }

  .ant-dropdown-menu{
    overflow-y: scroll;
    max-height: 250px;
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px; /* Adjust the max-width as needed */
}