.container {
  // margin-top: 100px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  overflow-y: auto;
  position: relative;

  .header {
    background-color: white;
    // width: 90%;
    /* Adding blur effect */
    backdrop-filter: blur(
      10px
    ); // adjust the pixel value to control the blur intensity
  }

  .header {
    position: fixed;
    z-index: 100;
    width: 80%;
    height: 80px;
    background-color: transparent;
    border-radius: 15px;
    backdrop-filter: blur(5px);
    padding-left: 10px;

    /* Centering content */
    display: flex; // Convert container to flexbox
    align-items: center; // Vertically center the content
    justify-content: space-between; // Horizontally center the content (optional if you want it)
    border: 2px solid rgba(0, 0, 0, 0.1); // Subtle light gray borde

    .left {
      /* Text styling */
      font-weight: bold; // Makes text bold
      color: black; // Makes text color black
      font-size: 30px;
      width: 50%;
    }

    .right {
      width: 50%;

      .row0 {
        display: flex;
        width: 100%;
        justify-content: right;
        // background-color: #0f1116;
        // background-color: #ffffff;
        height: 70px;

        .filter_header {
          // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
          /* Adjust as needed */
          background-color: #ffffff;
          border-radius: 15px;
          width: 100%;
          height: 70px;
          display: flex;
          justify-content: right;
          align-items: center;
          padding: 10px;
          // padding-top: 20px;
          flex-direction: row;

          .plateform_selector {
            width: 40%;
            margin-top: 25px;
            z-index: 999999;
          }

          .days_filter {
            width: 65%;
          }
        }
      }
    }
  }

  .row1 {
    margin-top: 100px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 30px;

    .col1 {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      /* Adjust as needed */
      width: 25%;
      height: 100px;
      background-color: #ffffff;
      border-radius: 15px;
      display: flex;
      flex-direction: row;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;

        .image {
          width: 50%;
          height: 50%;
          color: "#001529";
        }
      }

      .right {
        padding-top: 15px;
        width: 70%;
        display: flex;
        align-items: start;
        flex-direction: column;

        .heading {
          color: #cbcdd4;
          font-size: 1rem;
          // font-weight: bolder;
        }

        .number {
          margin-top: 10px;
          //   text-align: center;
          width: 100%;
          // background-color: blue;
          font-size: 2rem;
          // font-weight: bolder;
        }
      }
    }

    .col2 {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      /* Adjust as needed */

      width: 40%;
      height: 100px;
      background-color: #ffffff;
      border-radius: 15px;
      display: flex;
      flex-direction: row;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;

        .image {
          width: 50%;
          height: 50%;
          color: "#001529";
        }
      }

      .right {
        padding-top: 15px;
        width: 70%;
        display: flex;
        align-items: start;
        flex-direction: column;

        .heading {
          color: #cbcdd4;
          font-size: 1rem;
          // font-weight: bolder;
        }

        .content {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;

          .number1 {
            margin-top: 10px;
            text-align: center;
            width: 70%;
            // background-color: blue;
            font-size: 2rem;
            // font-weight: bolder;
            padding-right: 2px;

            .sub_txt {
              font-size: 1rem;
            }

            // border-right: solid 2px black;
          }

          .number2 {
            margin-top: 10px;
            text-align: start;
            width: 30%;
            margin-top: auto;
            margin-right: 10px;
            // background-color: blue;
            font-size: 1.3rem;

            // font-weight: bold;
            .sub_txt {
              font-size: 1rem;
            }
          }
        }
      }
    }

    .col3 {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      /* Adjust as needed */

      width: 25%;
      height: 100px;
      background-color: #ffffff;
      border-radius: 15px;
      display: flex;
      flex-direction: row;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;

        .image {
          width: 50%;
          height: 50%;
          color: "#001529";
        }
      }

      .right {
        padding-top: 15px;
        width: 70%;
        display: flex;
        align-items: start;
        flex-direction: column;

        .heading {
          color: #cbcdd4;
          font-size: 1rem;
          // font-weight: bolder;
        }

        .number {
          margin-top: 10px;
          text-align: start;
          width: 100%;
          // background-color: blue;
          font-size: 2rem;
          // font-weight: bolder;
        }
      }
    }
  }

  .row2 {
    margin-left: 30px;
    margin-top: 25px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    .col1 {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      /* Adjust as needed */
      width: 25%;
      height: 100px;
      background-color: #ffffff;
      border-radius: 15px;
      display: flex;
      flex-direction: row;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;

        .image {
          width: 50%;
          height: 50%;
          color: "#001529";
        }
      }

      .right {
        padding-top: 15px;
        width: 70%;
        display: flex;
        align-items: start;
        flex-direction: column;

        .heading {
          color: #cbcdd4;
          font-size: 1rem;
          // font-weight: bolder;
        }

        .number {
          margin-top: 10px;
          //   text-align: center;
          width: 100%;
          // background-color: blue;
          font-size: 2rem;
          // font-weight: bolder;
        }
      }
    }

    .col2 {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      /* Adjust as needed */
      width: 40%;
      height: 100px;
      background-color: #ffffff;
      border-radius: 15px;
      display: flex;
      flex-direction: row;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;

        .image {
          width: 50%;
          height: 50%;
          color: "#001529";
        }
      }

      .right {
        padding-top: 15px;
        width: 70%;
        display: flex;
        align-items: start;
        flex-direction: column;

        .heading {
          color: #cbcdd4;
          font-size: 1rem;
          // font-weight: bolder;
        }

        .content {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;

          .number1 {
            margin-top: 10px;
            text-align: left;
            width: 100%;
            // background-color: blue;
            font-size: 2rem;
            // font-weight: bolder;
            padding-right: 2px;

            .sub_txt {
              font-size: 1rem;
            }

            // border-right: solid 2px black;
          }

          .number2 {
            margin-top: 10px;
            text-align: start;
            width: 30%;
            margin-top: auto;
            margin-right: 10px;
            // background-color: blue;
            font-size: 1.3rem;

            // font-weight: bold;
            .sub_txt {
              font-size: 1rem;
            }
          }
        }
      }
    }

    .col3 {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      /* Adjust as needed */
      width: 25%;
      height: 100px;
      background-color: #ffffff;
      border-radius: 15px;
      display: flex;
      flex-direction: row;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;

        .image {
          width: 50%;
          height: 50%;
          color: "#001529";
        }
      }

      .right {
        padding-top: 15px;
        width: 70%;
        display: flex;
        align-items: start;
        flex-direction: column;

        .heading {
          color: #cbcdd4;
          font-size: 1rem;
          // font-weight: bolder;
          color: #cbcdd4;
        }

        .number {
          margin-top: 10px;
          text-align: start;
          width: 100%;
          color: #0f1116;
          font-size: 2rem;
          // font-weight: bolder;
        }
      }
    }
  }
  .row4 {
    margin-left: 30px;
    margin-top: 25px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    .col1 {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      /* Adjust as needed */
      width: 25%;
      height: 100px;
      background-color: #ffffff;
      border-radius: 15px;
      display: flex;
      flex-direction: row;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;

        .image {
          width: 50%;
          height: 50%;
          color: "#001529";
        }
      }

      .right {
        padding-top: 15px;
        width: 70%;
        display: flex;
        align-items: start;
        flex-direction: column;

        .heading {
          color: #cbcdd4;
          font-size: 1rem;
          // font-weight: bolder;
        }

        .number {
          margin-top: 10px;
          //   text-align: center;
          width: 100%;
          // background-color: blue;
          font-size: 2rem;
          // font-weight: bolder;
        }
      }
    }

    .col2 {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      /* Adjust as needed */
      width: 40%;
      height: 100px;
      background-color: #ffffff;
      border-radius: 15px;
      display: flex;
      flex-direction: row;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20%;

        .image {
          width: 50%;
          height: 50%;
          color: "#001529";
        }
      }

      .right {
        padding-top: 15px;
        width: 80%;
        display: flex;
        align-items: start;
        flex-direction: column;

        .heading {
          color: #cbcdd4;
          font-size: 1rem;
          // font-weight: bolder;
        }

        .content {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: start;

          .number1 {
            margin-top: 10px;
            text-align: left;
            width: 50%;
            // background-color: blue;
            font-size: 1.3rem;
            // font-weight: bolder;
            padding-right: 2px;

            .sub_txt {
              font-size: 1rem;
            }

            // border-right: solid 2px black;
          }

          .number2 {
            margin-top: 10px;
            text-align: start;
            width: 50%;
            margin-top: auto;
            margin-right: 10px;
            // background-color: blue;
            font-size: 1.3rem;

            // font-weight: bold;
            .sub_txt {
              font-size: 1rem;
            }
          }
        }
      }
    }

    .col3 {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      /* Adjust as needed */
      width: 25%;
      height: 100px;
      background-color: #ffffff;
      border-radius: 15px;
      display: flex;
      flex-direction: row;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;

        .image {
          width: 50%;
          height: 50%;
          color: "#001529";
        }
      }

      .right {
        padding-top: 15px;
        width: 70%;
        display: flex;
        align-items: start;
        flex-direction: column;

        .heading {
          color: #cbcdd4;
          font-size: 1rem;
          // font-weight: bolder;
          color: #cbcdd4;
        }

        .number {
          margin-top: 10px;
          text-align: start;
          width: 100%;
          color: #0f1116;
          font-size: 2rem;
          // font-weight: bolder;
        }
      }
    }
  }
  .row3 {
    margin-left: 30px;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    .col1 {
      display: flex;
      flex-direction: column;
      width: 58%;
      .tsv_channel_card_container {
        min-height: fit-content;
        max-height: 1900px;
        width: 100%;
        background-color: #ffffff;
        border-radius: 15px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

        /* Adjust as needed */
        .heading {
          padding: 15px;
          width: 100%;
          display: flex;
          flex-direction: row;
          .icon {
            width: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
            .image {
              width: 40px;
              height: 40px;
            }
          }
          .text {
            width: 90%;
            font-size: 2rem;
            font-weight: bold;
          }
        }

        .table_container {
        }
      }

      .tsv_viewer_card_container {
        margin-top: 20px;
        min-height: fit-content;
        max-height: 1900px;
        width: 100%;
        background-color: #ffffff;
        border-radius: 15px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

        /* Adjust as needed */
        .heading {
          padding: 15px;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          .icon {
            width: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
            .image {
              width: 40px;
              height: 40px;
            }
          }

          .text {
            width: 90%;
            font-size: 2rem;
            font-weight: bold;
          }

          .channel_selector {
            width: 30%;
          }
        }

        .table_container {
        }
      }
    }

    .col2 {
      min-height: 550px;
      max-height: fit-content;
      width: 38%;

      /* Adjust as needed */
      display: flex;
      flex-direction: column;

      .top_10_players {
        width: 100%;
        height: fit-content;
        background-color: #ffffff;
        border-radius: 15px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        .heading {
          width: 100%;
          display: flex;
          height: fit-content;
          // justify-content: flex-end;
          padding: 15px;
          align-items: end;
          flex-direction: column;

          // background-color: #cbcdd4;
          .card_heading {
            // background-color: #0f1116;
            width: 100%;
            height: 100px;
            display: flex;
            flex-direction: row;

            .icon {
              width: 30%;
              // background-color: #0f1116;
              display: flex;
              justify-content: center;
              align-items: center;

              .image {
                width: 70px;
                height: 70px;
              }
            }

            .text {
              width: 70%;
              font-size: 2.5rem;
              font-weight: bold;
            }
          }

          .drop_down_container {
            width: 100%;
            // background-color: #0f1116;
            width: 40%;
            height: 50px;
          }
        }

        .table_container {
        }
      }

      .games_played {
        width: 100%;
        height: fit-content;
        margin-top: 30px;
        background-color: #ffffff;
        border-radius: 15px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

        .heading {
          width: 100%;
          display: flex;
          height: fit-content;
          // justify-content: flex-end;
          padding: 15px;
          align-items: end;
          flex-direction: column;

          // background-color: #cbcdd4;
          .card_heading {
            // background-color: #0f1116;
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: row;

            .icon {
              width: 30%;
              // background-color: #0f1116;
              display: flex;
              justify-content: center;
              align-items: center;

              .image {
                width: 70px;
                height: 70px;
              }
            }

            .text {
              width: 70%;
              font-size: 2.5rem;
              font-weight: bold;
            }
          }

          .drop_down_container {
            width: 100%;
            // background-color: #0f1116;
            width: 40%;
            height: 50px;
          }
        }

        .table_container {
        }
      }

      .leader_board {
        width: 100%;
        height: fit-content;
        margin-top: 30px;
        background-color: #ffffff;
        border-radius: 15px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

        .heading {
          width: 100%;
          display: flex;
          height: fit-content;
          // justify-content: flex-end;
          padding: 15px;
          align-items: end;
          flex-direction: column;

          // background-color: #cbcdd4;
          .card_heading {
            // background-color: #0f1116;
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: row;

            .icon {
              width: 30%;
              // background-color: #0f1116;
              display: flex;
              justify-content: center;
              align-items: center;

              .image {
                width: 70px;
                height: 70px;
              }
            }

            .text {
              width: 70%;
              font-size: 2.5rem;
              font-weight: bold;
            }
          }

          .drop_down_container {
            width: 100%;
            // background-color: #0f1116;
            width: 40%;
            height: 50px;
          }
        }

        .options_container {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .drop_downs_container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            // background-color: #cbcdd4;
          }
          .controls {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // background-color: #0f1116;
            align-items: center;
          }
        }
      }

      .app_installs {
        width: 100%;
        height: fit-content;
        margin-top: 30px;
        background-color: #ffffff;
        padding-bottom: 20px;
        border-radius: 15px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

        .heading {
          width: 100%;
          display: flex;
          height: fit-content;
          // justify-content: flex-end;
          padding: 15px;
          align-items: end;
          flex-direction: column;

          // background-color: #cbcdd4;
          .card_heading {
            // background-color: #0f1116;
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: row;

            .icon {
              width: 30%;
              // background-color: #0f1116;
              display: flex;
              justify-content: center;
              align-items: center;

              .image {
                width: 70px;
                height: 70px;
              }
            }

            .text {
              width: 70%;
              font-size: 2.5rem;
              font-weight: bold;
            }
          }

          .drop_down_container {
            width: 100%;
            // background-color: #0f1116;
            width: 40%;
            height: 50px;
          }
        }

        .app_list_container {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          .app_details{
            display: flex;
            flex-direction: column;
            justify-content: center;
            // background-color: #0f111/6;
            align-items: center;
            .image{
              width: 50px;
              height: 50px;
            }
            .text{
              text-align: center;
              margin-top: 10px;
              font-size: 2rem;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
